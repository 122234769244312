@import '../index.scss';

.admin{
    background-color: rgb(244, 244, 244);
    height: 100vh;
    padding: 48px 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 48px;

    h1{
        font-size: 24px;
        font-weight: 600;
        color: $titleColor;
        width: calc((100% / 12)*11);
    }

    .admin_delete{
        z-index: 1000;
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.641);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
       

        &__modal{
            display: none;
            background-color: $mainBg;
            width: 50%;
            padding: 24px;
            display: flex;
            flex-direction: column;
            gap: 24px;
            font-size: 14px;
            text-align: center;
            border-radius: 10px;
        }

    }

    .admin_add{
        z-index: 100;
        position: fixed;
        top: 48px;
        right: calc((100vw / 20));

        font-size: 12px;
        background-color: transparent;
        border: none;
        color: $mainColor;
        cursor: pointer;
        padding: 10px;
        transition: all 100ms linear;
        border-radius: 4px;

        &:hover{
            background-color: $mainColorLight;
        }
    }

    table{

        width: calc((100% / 12)*11);
        text-align: left;
        border-collapse: collapse;
        font-size: 12px;
        color: $fontColor;
        
        border: solid 1px $borderColor;

        thead{
            background-color: $thirdBg;
            th{
                padding: 15px 20px;
                color: $titleColor;
                font-weight: 500;
            }
        }

        tbody{
            background-color: $mainBg;
            tr{
                transition: all 100ms linear;
                cursor: pointer;
                background-color: white;
                
                td{
                    padding: 15px 20px;
                    
                    button{
                        font-size: 12px;
                        background-color: transparent;
                        border: none;
                        
                        cursor: pointer;
                        padding: 10px;
                        transition: all 100ms linear;
                        border-radius: 4px;

                        &:hover{
                            background-color: $thirdBg;
                        }
                    }
                }

                .tdAction{
                    display: flex;
                    gap: 20px;
                    width: 400px;
                    justify-content: space-between;
                }
                &:nth-child(even) {
                    background: rgb(248, 248, 248);
                }
            }
            
            .selectedRow{
                //background-color: $mainColorLight;
            }
        }
    }

    &__form{
        z-index: 1000;
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.641);
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .close{
            position: fixed;
            top: 24px;
            right: 48px;
            font-size: 12px;
            background-color: transparent;
            border: none;
            color: $fontColor;
            transition: 100ms linear all;
            padding: 5px;
            border-radius: 3px;
            color: $mainColor;
            cursor: pointer;

            &:hover{
                background-color: $mainColorLight;
            }
        }

        form{
            background-color: rgb(244, 244, 244);
            overflow-y: scroll;
            height: 100%;
            width: 50%;
            padding: 24px;
            display: flex;
            flex-direction: column;
            gap: 24px;

            h2{
                font-weight: 600;
                color: $titleColor;
            }

            div{
                display: flex;
                gap: 24px;
            }

            label{
                display: flex;
                flex-direction: column;
                gap: 10px;
                width: 100%;
                span{
                    font-size: 12px;
                    color: $fontColor;
                }
                input{
                    height: 48px;
                    border: 1px solid $borderColor;
                    outline-color: none;
                    transition: all 100 linear;
                    border-radius: 5px;
                    padding: 0 10px;

                    &:focus{
                        outline: none;
                        border: 1px solid $mainColor;
                    }
                }
            }
        }
    }
}